export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDjGZuu-NJ0tSsVHewZrI8pt3S7ZUIhpeo",
    authDomain: "uberclone-b9619.firebaseapp.com",
    databaseURL: "https://uberclone-b9619-default-rtdb.firebaseio.com",
    projectId: "uberclone-b9619",
    storageBucket: "uberclone-b9619.appspot.com",
    messagingSenderId: "701272221797",
    appId: "1:701272221797:web:6ea135c5b8fb6275bdbe8c",
    measurementId: "G-DT5QGJTWF6"
  },
  adminEmail: 'admin@rush.com', // you need to create new account in firebase auth manually
  paymentFunctionUrl: 'https://us-central1-uberclone-b9619.cloudfunctions.net/payments',
};
